@use "sass:map";

$white: #ffffff;
$primary: #004355;

// https://huemint.com/bootstrap-plus/
$theme-colors: (
    "light":      #c7d9e7,
    "dark":       #050509,
    "primary":    $primary,
    "secondary":  #327e81,
    "info":       #2990a4,
    "accent1":    #344a51,
    "accent2":    #ff8d22,
    "accent3":    #9aaeaf,
    "success":    #00a956,
    "warning":    #e9ca00,
    "danger":     #f7001c,
);

$body-bg: white;
$body-color: white;

body {
    background-image: linear-gradient(to bottom right, #ff8d22, #327e81, #004355, #ff8d22);
    color: white;
}

//background-image: linear-gradient(to bottom right, #ff8d22, #327e81, #004355, #ff8d22);

@import '~bootstrap/scss/bootstrap.scss';
// @import '~bootstrap-icons/font/bootstrap-icons.scss';